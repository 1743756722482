var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',[_c('v-row',{staticClass:"pl-2"},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('h3',{staticClass:"font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.question.content)}})]),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('h4',[_vm._v("Nhập đáp án")]),_c('CkContent',{attrs:{"validateName":"question","content":_vm.answer.content},on:{"getData":_vm.getAnswerContent}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(_vm.error)+" ")])],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('h4',[_vm._v("Đáp án đúng")]),_c('v-checkbox',{attrs:{"color":"success","value":_vm.translate.isAnswer,"hide-details":""},model:{value:(_vm.translate.isAnswer),callback:function ($$v) {_vm.$set(_vm.translate, "isAnswer", $$v)},expression:"translate.isAnswer"}})],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('h4',[_vm._v("Nhập giải nghĩa")]),_c('CkContent',{attrs:{"validateName":"translate","content":_vm.translate.content},on:{"getData":_vm.getTranslateContent}})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-center justify-center"},[_c('div',{staticClass:"my-4"},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"blue-grey"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Hủy")]),(!_vm.isUpdate)?_c('v-btn',{attrs:{"color":"primary","type":"submit"},on:{"click":_vm.submitAnswer}},[_vm._v("Lưu")]):_vm._e(),(_vm.isUpdate)?_c('v-btn',{attrs:{"color":"primary","type":"submit"},on:{"click":_vm.submitAnswer}},[_vm._v("Cập nhật")]):_vm._e()],1)])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.answers,"items-per-page":5},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteAnswer(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"question-content",domProps:{"innerHTML":_vm._s(item.content)}})]}},{key:"item.isDisplay",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mb-6",attrs:{"color":"success","value":item.translate.isAnswer,"hide-details":""},on:{"click":function($event){return _vm.updateIsAnswer(item.id, item.translate.isAnswer)}},model:{value:(item.translate.isAnswer),callback:function ($$v) {_vm.$set(item.translate, "isAnswer", $$v)},expression:"item.translate.isAnswer"}})]}},{key:"item.translate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"question-content",domProps:{"innerHTML":_vm._s(item.translate.content)}})]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }