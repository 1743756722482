<template>
  <v-app>
    <div>
      <v-row class="pl-2">
        <v-col class="pa-5" cols="12" sm="12" md="12">
          <h3 class="font-weight-bold" v-html="question.content"></h3>
        </v-col>
        <v-col class="pa-5" cols="12" sm="12" md="12">
          <h4>Nhập đáp án</h4>
          <CkContent
            validateName="question"
            :content="answer.content"
            @getData="getAnswerContent"
          ></CkContent>
          <span class="validate-error text-danger text-danger">
            {{ error }}
          </span>
        </v-col>
        <v-col class="pa-5" cols="12" sm="12" md="12">
          <h4>Đáp án đúng</h4>
          <v-checkbox
            v-model="translate.isAnswer"
            color="success"
            :value="translate.isAnswer"
            hide-details
          ></v-checkbox>
        </v-col>
        <v-col class="pa-5" cols="12" sm="12" md="12">
          <h4>Nhập giải nghĩa</h4>
          <CkContent
            validateName="translate"
            :content="translate.content"
            @getData="getTranslateContent"
          ></CkContent>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="text-center justify-center">
            <div class="my-4">
              <v-btn
                class="ma-2 white--text"
                color="blue-grey"
                @click="$router.go(-1)"
                >Hủy</v-btn
              >
              <v-btn
                v-if="!isUpdate"
                color="primary"
                type="submit"
                @click="submitAnswer"
                >Lưu</v-btn
              >
              <v-btn
                v-if="isUpdate"
                color="primary"
                type="submit"
                @click="submitAnswer"
                >Cập nhật</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="answers"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="deleteAnswer(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:item.content="{ item }">
              <div class="question-content" v-html="item.content"></div>
            </template>
            <template v-slot:item.isDisplay="{ item }">
              <v-checkbox
                v-model="item.translate.isAnswer"
                color="success"
                v-on:click="updateIsAnswer(item.id, item.translate.isAnswer)"
                :value="item.translate.isAnswer"
                hide-details
                class="mb-6"
              ></v-checkbox>
            </template>
            <template v-slot:item.translate="{ item }">
              <div class="question-content" v-html="item.translate.content"></div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>
<script>
import CkContent from "@/components/ck-content";
import {
  GET_EXERCISE_DETAIL,
  SAVE_EXERCISE_CHOOSE_ANSWER,
  UPDATE_EXERCISE_CHOOSE_ANSWER,
  DELETE_CHOOSE_ANSWER,
  GET_QUESTION_DETAIL,
  UPDATE_IS_ANSWER,
  GET_ANSWER_DETAIL,
  GET_TRANSLATE_ANSWER_DETAIL,
  GET_ANSWER_LIST_DETAIL
} from "@/store/exercises.module";
import { mapGetters } from "vuex";
export default {
  components: {
    CkContent,
  },
  data: () => ({
    checkbox: false,
    isUpdate: false,
    error: "",
    isAnswer: false,
    isQuestion: false,
    selected: "",
    headers: [
      {text: "Đáp án", value: "content"},
      {text: "Giải nghĩa", value: "translate"},
      {text: "Đáp án đúng", value: "isDisplay"},
      {text: "Thao tác", value: "actions", sortable: false},
    ],
  }),
  computed: {
    ...mapGetters({
      exercise: "exerciseDetail",
      answers: "answerList",
      question: "questionDetail",
      answer: "answerDetail",
      translate: "translateAnswerDetail",
    }),
  },
  async created() {
    await this.$store.dispatch(GET_EXERCISE_DETAIL, {
      exercise_slug: this.$route.query.exercise_slug,
    });
    await this.$store.dispatch(GET_QUESTION_DETAIL, {
      question_id: this.$route.query.question_id,
    });
    await this.$store.dispatch(GET_TRANSLATE_ANSWER_DETAIL, {
      answer_id: this.$route.query.answer_id,
    });
    await this.$store.dispatch(GET_ANSWER_DETAIL, {
      answer_id: this.$route.query.answer_id,
    });
    await this.$store.dispatch(GET_ANSWER_LIST_DETAIL, {
      question_id: this.$route.query.question_id,
    });
  },
  mounted() {
    if (this.$route.query.answer_id) {
      this.isUpdate = true;
    }
  },
  methods: {
    deleteAnswer(item) {
      confirm("Are you sure you want to delete this item?") &&
        this.$store
          .dispatch(DELETE_CHOOSE_ANSWER, {
            choose_answer_id: item.id,
          })
          .then(() => {
            this.$store.dispatch(GET_ANSWER_LIST_DETAIL, {
              question_id: this.$route.query.question_id,
            });
          });
    },
    getAnswerContent(value) {
      this.answer.content = value;
    },
    getTranslateContent(value) {
      this.translate.content = value;
    },
    updateIsAnswer(id, isAnswer) {
      if (isAnswer === null) {
        isAnswer = false;
      }
      this.$store.dispatch(UPDATE_IS_ANSWER, {
        choose_answer_id: id,
        isAnswer: isAnswer
      });
    },
    editItem(item) {
      this.$router.push({
          path: "/exercise/translate/answer/add",
          query: {exercise_slug: this.exercise.slug, question_id: item.exercised_question_id, answer_id: item.id},
        }).then(() => {
          this.$router.go(0);
        });
    },
    submitAnswer() {
      if (this.answer.content == null || this.answer.content == "") {
        this.error = "Đáp án không được để trống";
      } else {
        this.translate.isQuestion = this.isQuestion;
        this.answer.exercised_question_id = this.question.id;
        this.answer.is_correct = this.translate.isAnswer;
        this.answers.push(this.question.answers[0]);
        if (this.isUpdate) {
          this.$store
            .dispatch(UPDATE_EXERCISE_CHOOSE_ANSWER, {
              question: this.question,
              translate: this.translate,
              answer: this.answer,
              exercise: this.exercise,
            })
            .then(() => {
              // this.$router.go(-1);
            });
        }
        if (!this.isUpdate) {
          this.$store
            .dispatch(SAVE_EXERCISE_CHOOSE_ANSWER, {
              question: this.question,
              exercise: this.exercise,
              answer: this.answer,
              translate: this.translate,
            })
            .then(() => {
              // this.$router.go(0);
            });
        }
      }
    },
  },
};
</script>